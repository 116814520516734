import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import MarcoBonomo from '../images/marco_bonomo.jpg';
import { MDXRenderer } from "gatsby-plugin-mdx"

function IndexPage({data}) {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`,`vuejs`,`kubernetes`,`laravel`,'belluno','web']}
        title="Bonsai - Belluno web apps"
      />
        <div className="w-full md:w-1/3">
          <img
            src={MarcoBonomo}
            alt="Marco Bonomo"
            className="w-48 h-48 rounded-full bg-gray-200 border border-gray mt-6 p-1"
          />
        </div>
        <div className="my-8 flex flex-col justify-center pr-4 lg:w-2/3">
          <h1
            className="sm:text-5xl text-4xl font-semibold leading-none tracking-tight mb-4"
          >{`Hi, I'm Marco.`}</h1>
          <h2 className="sm:text-5xl text-3xl font-normal leading-tight tracking-tight mb-8">
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </h2>
          <p className="sm:mt-4"></p>
        </div>
    </Layout>
  );
}

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.node.isRequired
};


export const pageQuery = graphql`
  query MyQuery {
  mdx(frontmatter: {title: {eq: "Bonsai"}}) {
    id
    tableOfContents
    body
  }
}
`